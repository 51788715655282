<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="8">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Basic</div>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.basicForm.openForm(dog)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Basic Details</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>

            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Breed</th>
                  <td>
                    <div v-if="dog.breed">{{ dog.breed.name }}</div>
                  </td>

                  <th>Grade</th>
                  <td>{{ dog.grade }}</td>
                </tr>

                <tr>
                  <th>ID</th>
                  <td>{{ dog.identifier }}</td>
                  <th>Size</th>
                  <td>
                    <div v-if="dog.size">{{ dog.size.name }}</div>
                  </td>
                </tr>

                <tr>
                  <th>Gender</th>
                  <td>{{ dog.gender == "F" ? "Female" : "Male" }}</td>
                  <th>Colour</th>
                  <td>
                    <div v-if="dog.colour">{{ dog.colour.name }}</div>
                  </td>
                </tr>

                <tr>
                  <th>Kennel Name</th>
                  <td>{{ dog.kennel_name }}</td>
                  <th>Markings</th>
                  <td>
                    <div v-if="dog.marking">{{ dog.marking.name }}</div>
                  </td>
                </tr>

                <tr>
                  <th>D.O.B</th>
                  <td>
                    <div v-if="dog.dob">{{ formatDate(dog.dob) }}</div>
                  </td>
                  <th>Coat Type</th>
                  <td>
                    <div v-if="dog.coat_type">{{ dog.coat_type.name }}</div>
                  </td>
                </tr>

                <tr>
                  <th>Retiral Date</th>
                  <td>
                    <div v-if="dog.retiral_date">
                      {{ formatDate(dog.retiral_date) }}
                    </div>
                  </td>
                  <th>Retiral Comments</th>
                  <td>
                    {{ dog.retiral_comments }}
                  </td>
                </tr>

                <tr>
                  <th>Colour D.N.A.</th>
                  <td>{{ dog.colour_dna }}</td>
                  <th>Age</th>
                  <td>
                    <div v-if="dog.dob">{{ this.age(dog.dob) }}</div>
                  </td>
                </tr>

                <tr>
                  <th>Microchip</th>
                  <td>{{ dog.microchip }}</td>
                  <th>Location</th>
                  <td>
                    <div v-if="dog.location == 'lhl' || dog.location == 'fch'">
                      {{ dog.location.toUpperCase() }}
                    </div>
                    <div v-else>
                      {{ this.capitalize(dog.location) }}
                    </div>
                  </td>
                </tr>

                <tr>
                  <th>Status</th>
                  <td>
                    {{ this.capitalize(dog.status) }}
                  </td>
                </tr>

                <tr v-if="dog.status == 'puppy'">
                  <th>Time of Birth</th>
                  <td>{{ dog.time_of_birth }}</td>
                </tr>

                <tr v-if="dog.status == 'puppy'">
                  <th>Temperament</th>
                  <td>{{ dog.temperament }}</td>
                </tr>
                <tr v-if="dog.status == 'puppy'">
                  <th>Puppy Collection Date</th>
                  <td>{{ dog.puppy_collection_date }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Notes</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.noteDialog.openForm(dog)"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>
            <v-data-table
              :headers="noteTableHeaders"
              :items="dog.dog_notes"
              no-data-text="No Notes"
            >
              <template v-slot:item.created_at="{ item }">
                {{ formatDateTime(item.created_at, true) }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>

        <v-col cols="4">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Profile Image</div>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.profileImageForm.openForm(dog)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Basic Details</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>

            <div v-if="dog.profile_image">
              <v-img
                v-if="dog.profile_image"
                :src="dog.profile.asset_urls.url"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
              >
                <v-card-title>{{ dog.name }}</v-card-title>
              </v-img>
              <v-img
                v-else
                src="@/assets/image-placeholder.svg"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
              >
                <v-card-title>{{ dog.name }}</v-card-title>
              </v-img>
            </div>
          </v-card>

          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Sire</div>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.sireForm.openForm(dog)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Change Sire</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>

            <div v-if="dog.sire">
              <router-link
                :to="{
                  name: 'module-lhl-dogs-individual',
                  params: { dogId: dog.sire.id },
                }"
              >
                <v-img
                  v-if="dog.sire.profile_image"
                  :src="dog.sire.profile.asset_urls.url"
                  class="white--text align-end"
                  gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
                >
                  <v-card-title>{{ dog.sire.name }}</v-card-title>
                </v-img>
                <v-img
                  v-else
                  src="@/assets/image-placeholder.svg"
                  class="white--text align-end"
                  gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
                >
                  <v-card-title>{{ dog.sire.name }}</v-card-title>
                </v-img>
              </router-link>
            </div>
          </v-card>

          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Dam</div>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.damForm.openForm(dog)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Basic Details</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>

            <div v-if="dog.dam">
              <router-link
                :to="{
                  name: 'module-lhl-dogs-individual',
                  params: { dogId: dog.dam.id },
                }"
              >
                <v-img
                  v-if="dog.dam.profile_image"
                  :src="dog.dam.profile.asset_urls.url"
                  class="white--text align-end"
                  gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
                >
                  <v-card-title>{{ dog.dam.name }}</v-card-title>
                </v-img>
                <v-img
                  v-else
                  src="@/assets/image-placeholder.svg"
                  class="white--text align-end"
                  gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
                >
                  <v-card-title>{{ dog.dam.name }}</v-card-title>
                </v-img>
              </router-link>
            </div>
          </v-card>

          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Origin Contact</div>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.contactForm.openForm(dog)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Basic Details</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text v-if="dog.customer_id">
              <div class="mb-5">{{ dog.origin_contact.full_name }}</div>

              <router-link
                :to="{
                  name: 'module-lhl-customers-individual',
                  params: { customerId: dog.origin_contact.id },
                }"
              >
                View Contact Details</router-link
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <BasicForm ref="basicForm" :dog="dog" />
      <ProfileImageForm ref="profileImageForm" />
      <ContactForm ref="contactForm" />
      <SireForm ref="sireForm" />
      <DamForm ref="damForm" />
      <NoteDialog ref="noteDialog" />
    </v-container>
  </div>
</template>

<script>
import BasicForm from "../components/BasicForm";
import ProfileImageForm from "../components/basic/ProfileImageForm";
import SireForm from "../components/basic/SireForm";
import DamForm from "../components/basic/DamForm";
import ContactForm from "../components/basic/ContactForm";
import NoteDialog from "../components/NoteDialog.vue";

export default {
  components: {
    BasicForm,
    SireForm,
    DamForm,
    ProfileImageForm,
    ContactForm,
    NoteDialog,
  },

  data() {
    return {
      noteTableHeaders: [
        { text: "Date/Time", value: "created_at" },
        { text: "User", value: "user.full_name" },
        { text: "Note", value: "note_text" },
      ],
    };
  },

  computed: {
    dog() {
      let dog = this.$store.getters["lhl/dogs/get"];

      return dog;
    },
  },
};
</script>
